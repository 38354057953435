import Workcard from "./Workcard"
import Work from "./Work"
const Project = () =>{

    return(
        <div id="projects">
        <Work />
        </div>
    )
}


export default Project